/**
 * @fuegokit/tokens 0.25.1
 * Do not edit directly
 * Generated on Fri, 24 Jan 2025 15:14:05 GMT
 */
export default {
  radii: {
    "0": "0px",
    "1": "2px",
    "2": "4px",
    "3": "6px",
    "4": "8px",
    "5": "12px",
    none: "0px",
    xsmall: "2px",
    small: "4px",
    medium: "6px",
    large: "8px",
    xlarge: "12px",
    round: "99999px",
  },
};
