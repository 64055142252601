/**
 * @fuegokit/tokens 0.25.1
 * Do not edit directly
 * Generated on Fri, 24 Jan 2025 15:13:53 GMT
 */
export default {
  scale: {
    gray: {
      "100": "#ebecf0",
      "200": "#dfe1e6",
      "300": "#c1c7d0",
      "400": "#8993a4",
      "500": "#6b778c",
      "600": "#57606a",
      "700": "#424a53",
      "800": "#32383f",
      "900": "#24292f",
      "000": "#fafbfc",
    },
    neutral: {
      "100": "#f7f8f9",
      "200": "#f1f2f4",
      "300": "#dcdfe4",
      "400": "#b3b9c4",
      "500": "#8590a2",
      "600": "#758195",
      "700": "#626f86",
      "800": "#44546f",
      "900": "#2c3e5d",
      "1000": "#172b4d",
      "1100": "#091e42",
      "000": "#ffffff",
    },
    neutralAlpha: {
      "100": "#091e4208",
      "200": "#091e420f",
      "300": "#091e4224",
      "400": "#091e4224",
      "500": "#091e427d",
      "600": "#091e428f",
      "700": "#091e429e",
      "800": "#091e42ba",
      "900": "#091e42d6",
      "000": "#ffffff00",
    },
    blue: {
      "100": "#cce0ff",
      "200": "#85b8ff",
      "300": "#579dff",
      "400": "#388bff",
      "500": "#1d7afc",
      "600": "#0c66e4",
      "700": "#0055cc",
      "800": "#09326c",
      "900": "#1c2b41",
      "000": "#e9f2ff",
    },
    teal: {
      "100": "#c6edfb",
      "200": "#9dd9ee",
      "300": "#6cc3e0",
      "400": "#42b2d7",
      "500": "#2898bd",
      "600": "#227d9b",
      "700": "#206a83",
      "800": "#164555",
      "900": "#1e3137",
      "000": "#e7f9ff",
    },
    purple: {
      "100": "#dfd8fd",
      "200": "#b8acf6",
      "300": "#9f8fef",
      "400": "#8f7ee7",
      "500": "#8270db",
      "600": "#6e5dc6",
      "700": "#5e4db2",
      "800": "#352c63",
      "900": "#2b273f",
      "000": "#f3f0ff",
    },
    green: {
      "100": "#baf3db",
      "200": "#7ee2b8",
      "300": "#4bce97",
      "400": "#2abb7f",
      "500": "#22a06b",
      "600": "#1f845a",
      "700": "#216e4e",
      "800": "#164b35",
      "900": "#1c3329",
      "000": "#dcfff1",
    },
    yellow: {
      "100": "#f8e6a0",
      "200": "#f5cd47",
      "300": "#e2b203",
      "400": "#cf9f02",
      "500": "#b38600",
      "600": "#946f00",
      "700": "#7f5f01",
      "800": "#533f04",
      "900": "#332e1b",
      "000": "#fff7d6",
    },
    red: {
      "100": "#ffd5d2",
      "200": "#fd9891",
      "300": "#f87168",
      "400": "#f15b50",
      "500": "#e2483d",
      "600": "#c9372c",
      "700": "#ae2e24",
      "800": "#5d1f1a",
      "900": "#42221f",
      "000": "#ffeceb",
    },
    orange: {
      "100": "#fedec8",
      "200": "#fec195",
      "300": "#fea362",
      "400": "#f38a3f",
      "500": "#e56910",
      "600": "#c25100",
      "700": "#a54800",
      "800": "#702e00",
      "900": "#38291e",
      "000": "#fff3eb",
    },
    magenta: {
      "100": "#fdd0ec",
      "200": "#f797d2",
      "300": "#e774bb",
      "400": "#da62ac",
      "500": "#cd519d",
      "600": "#ae4787",
      "700": "#943d73",
      "800": "#50253f",
      "900": "#3d2232",
      "000": "#ffecf8",
    },
    lime: {
      "100": "#d3f1a7",
      "200": "#b3df72",
      "300": "#94c748",
      "400": "#82b536",
      "500": "#6a9a23",
      "600": "#5b7f24",
      "700": "#4c6b1f",
      "800": "#37471f",
      "900": "#28311b",
      "000": "#efffd6",
    },
  },
  elevation: {
    surface: {
      default: {
        "[default]": "#ffffff",
        hovered: "#f1f2f4",
        pressed: "#dcdfe4",
      },
      sunken: "#f7f8f9",
      raised: {
        default: "#ffffff",
        hovered: "#f7f8f9",
        pressed: "#f1f2f4",
      },
      overlay: {
        default: "#ffffff",
        hovered: "#f1f2f4",
        pressed: "#dcdfe4",
      },
    },
    shadow: {
      raised: "0px 1px 1px 0px #091e4240, 0px 0px 1px 0px #091e424f",
      overflow: {
        default: "0px 0px 12px 0px #0304048f, 0px 0px 1px 0px #03040480",
        spread: "#091e4229",
        perimeter: "#091e421f",
      },
      overlay: "0px 8px 12px 0px #091e4226, 0px 0px 1px 0px #091e424f",
    },
  },
  border: {
    default: "#091e4224",
    bold: "#758195",
    selected: "#1d7afc",
    focused: "#388bff",
    subtle: "#091e420f",
    input: "#8590a2",
    inverse: "#ffffff",
    disabled: "#091e420f",
    brand: "#0c66e4",
    danger: "#e2483d",
    warning: "#e56910",
    success: "#22a06b",
    discovery: "#8270db",
    information: "#1d7afc",
    accent: {
      blue: "#1d7afc",
      red: "#e2483d",
      orange: "#e56910",
      yellow: "#b38600",
      green: "#22a06b",
      magenta: "#cd519d",
      purple: "#8270db",
      teal: "#2898bd",
      gray: "#8590a2",
    },
    table: {
      default: "#091e4224",
      container: "#ffffff00",
    },
  },
  background: {
    accent: {
      blue: {
        subtlest: "#e9f2ff",
        subtler: "#cce0ff",
        subtle: "#579dff",
        bolder: "#0c66e4",
      },
      red: {
        subtlest: "#ffeceb",
        subtler: "#ffd5d2",
        subtle: "#f87168",
        bolder: "#c9372c",
      },
      orange: {
        subtlest: "#fff3eb",
        subtler: "#fedec8",
        subtle: "#fea362",
        bolder: "#c25100",
      },
      yellow: {
        subtlest: "#fff7d6",
        subtler: "#f8e6a0",
        subtle: "#f5cd47",
        bolder: "#946f00",
      },
      green: {
        subtlest: "#dcfff1",
        subtler: "#baf3db",
        subtle: "#4bce97",
        bolder: "#1f845a",
      },
      purple: {
        subtlest: "#f3f0ff",
        subtler: "#dfd8fd",
        subtle: "#9f8fef",
        bolder: "#6e5dc6",
      },
      teal: {
        subtlest: "#e7f9ff",
        subtler: "#c6edfb",
        subtle: "#6cc3e0",
        bolder: "#227d9b",
      },
      magenta: {
        subtlest: "#ffecf8",
        subtler: "#fdd0ec",
        subtle: "#e774bb",
        bolder: "#ae4787",
      },
      lime: {
        subtlest: "#efffd6",
        subtler: "#d3f1a7",
        subtle: "#94c748",
        bolder: "#5b7f24",
      },
      gray: {
        subtlest: {
          default: "#f1f2f4",
          hovered: "#dcdfe4",
          pressed: "#8590a2",
        },
        subtler: {
          default: "#dcdfe4",
          hovered: "#b3b9c4",
          pressed: "#8590a2",
        },
        subtle: {
          default: "#8590a2",
          hovered: "#b3b9c4",
          pressed: "#dcdfe4",
        },
        bolder: {
          default: "#626f86",
          hovered: "#44546f",
          pressed: "#2c3e5d",
        },
      },
    },
    input: {
      default: "#ffffff",
      hovered: "#f7f8f9",
      pressed: "#ffffff",
    },
    inverse: {
      subtle: {
        default: "#00000029",
        hovered: "#0000003d",
        pressed: "#00000052",
      },
    },
    neutral: {
      default: {
        "[default]": "#091e420f",
        hovered: "#091e4224",
        pressed: "#091e4224",
      },
      subtle: {
        default: "#00000000",
        hovered: "#091e420f",
        pressed: "#091e4224",
      },
      bold: {
        default: "#44546f",
        hovered: "#2c3e5d",
        pressed: "#172b4d",
      },
    },
    brand: {
      subtlest: {
        default: "#e9f2ff",
        hovered: "#cce0ff",
        pressed: "#85b8ff",
      },
      bold: {
        default: "#0c66e4",
        hovered: "#0055cc",
        pressed: "#09326c",
      },
      boldest: {
        default: "#1c2b41",
        hovered: "#09326c",
        pressed: "#0055cc",
      },
    },
    selected: {
      default: {
        "[default]": "#e9f2ff",
        hovered: "#cce0ff",
        pressed: "#85b8ff",
      },
      bold: {
        default: "#0c66e4",
        hovered: "#0055cc",
        pressed: "#09326c",
      },
    },
    disabled: "#f7f8f9",
    information: {
      default: {
        "[default]": "#e9f2ff",
        hovered: "#cce0ff",
        pressed: "#85b8ff",
      },
      bold: {
        default: "#0c66e4",
        hovered: "#0055cc",
        pressed: "#09326c",
      },
    },
    danger: {
      default: {
        "[default]": "#ffeceb",
        hovered: "#ffd5d2",
        pressed: "#fd9891",
      },
      bold: {
        default: "#c9372c",
        hovered: "#ae2e24",
        pressed: "#5d1f1a",
      },
    },
    success: {
      default: {
        "[default]": "#dcfff1",
        hovered: "#baf3db",
        pressed: "#7ee2b8",
      },
      bold: {
        default: "#1f845a",
        hovered: "#216e4e",
        pressed: "#164b35",
      },
    },
    discovery: {
      default: {
        "[default]": "#f3f0ff",
        hovered: "#dfd8fd",
        pressed: "#b8acf6",
      },
      bold: {
        default: "#6e5dc6",
        hovered: "#5e4db2",
        pressed: "#352c63",
      },
    },
    warning: {
      default: {
        "[default]": "#fff7d6",
        hovered: "#f8e6a0",
        pressed: "#f8e6a0",
      },
      bold: {
        default: "#f5cd47",
        hovered: "#e2b203",
        pressed: "#cf9f02",
      },
    },
  },
  blanket: {
    default: "#091e427d",
    selected: "#388bff14",
    danger: "#ef5c4814",
  },
  text: {
    default: "#172b4d",
    subtle: "#44546f",
    subtlest: "#626f86",
    disabled: "#8590a2",
    selected: "#0c66e4",
    inverse: "#ffffff",
    success: "#216e4e",
    danger: "#ae2e24",
    information: "#0055cc",
    warning: {
      default: "#a54800",
      inverse: "#172b4d",
    },
    discovery: "#5e4db2",
    brand: "#0c66e4",
    accent: {
      blue: {
        default: "#0055cc",
        bolder: "#09326c",
      },
      red: {
        default: "#ae2e24",
        bolder: "#5d1f1a",
      },
      orange: {
        default: "#a54800",
        bolder: "#702e00",
      },
      yellow: {
        default: "#7f5f01",
        bolder: "#533f04",
      },
      green: {
        default: "#216e4e",
        bolder: "#164b35",
      },
      purple: {
        default: "#5e4db2",
        bolder: "#352c63",
      },
      teal: {
        default: "#206a83",
        bolder: "#164555",
      },
      magenta: {
        default: "#943d73",
        bolder: "#50253f",
      },
      gray: {
        default: "#44546f",
        bolder: "#091e42",
      },
      lime: {
        default: "#4c6b1f",
        bolder: "#37471f",
      },
    },
  },
  link: {
    default: "#0c66e4",
    hovered: "#0c66e4",
    pressed: "#0055cc",
    visited: "#5e4db2",
  },
  icon: {
    accent: {
      blue: "#1d7afc",
      red: "#c9372c",
      orange: "#e56910",
      yellow: "#b38600",
      green: "#22a06b",
      purple: "#8270db",
      teal: "#2898bd",
      magenta: "#cd519d",
      gray: "#758195",
      lime: "#6a9a23",
    },
    default: "#44546f",
    subtle: "#626f86",
    inverse: "#ffffff",
    disabled: "#8590a2",
    brand: "#0c66e4",
    selected: "#0c66e4",
    danger: "#c9372c",
    success: "#22a06b",
    discovery: "#8270db",
    information: "#1d7afc",
    warning: {
      default: "#e56910",
      inverse: "#172b4d",
    },
  },
  interaction: {
    hovered: "#00000029",
    pressed: "#00000052",
  },
  skeleton: {
    default: "#091e420f",
    subtle: "#091e4208",
  },
  opacity: {
    disabled: 0.2,
    loading: 0.4,
  },
  chart: {
    brand: {
      default: "#1d7afc",
      hovered: "#0c66e4",
    },
    neutral: {
      default: "#8590a2",
      hovered: "#758195",
    },
    success: {
      default: {
        "[default]": "#22a06b",
        hovered: "#1f845a",
      },
      bold: {
        default: "#216e4e",
        hovered: "#164b35",
      },
    },
    danger: {
      default: {
        "[default]": "#f15b50",
        hovered: "#e2483d",
      },
      bold: {
        default: "#ae2e24",
        hovered: "#5d1f1a",
      },
    },
    warning: {
      default: {
        "[default]": "#b38600",
        hovered: "#946f00",
      },
      bold: {
        default: "#7f5f01",
        hovered: "#533f04",
      },
    },
    information: {
      default: {
        "[default]": "#1d7afc",
        hovered: "#0c66e4",
      },
      bold: {
        default: "#0055cc",
        hovered: "#09326c",
      },
    },
    discovery: {
      default: {
        "[default]": "#8f7ee7",
        hovered: "#8270db",
      },
      bold: {
        default: "#5e4db2",
        hovered: "#352c63",
      },
    },
    categorical: {
      "1": {
        default: "#2898bd",
        hovered: "#227d9b",
      },
      "2": {
        default: "#5e4db2",
        hovered: "#352c63",
      },
      "3": {
        default: "#e56910",
        hovered: "#c25100",
      },
      "4": {
        default: "#943d73",
        hovered: "#50253f",
      },
      "5": {
        default: "#09326c",
        hovered: "#1c2b41",
      },
      "6": {
        default: "#8f7ee7",
        hovered: "#8270db",
      },
      "7": {
        default: "#50253f",
        hovered: "#3d2232",
      },
      "8": {
        default: "#a54800",
        hovered: "#702e00",
      },
    },
    blue: {
      bold: {
        default: "#388bff",
        hovered: "#388bff",
      },
      bolder: {
        default: "#1d7afc",
        hovered: "#0c66e4",
      },
      boldest: {
        default: "#0055cc",
        hovered: "#09326c",
      },
    },
    red: {
      bold: {
        default: "#f15b50",
        hovered: "#e2483d",
      },
      bolder: {
        default: "#e2483d",
        hovered: "#c9372c",
      },
      boldest: {
        default: "#ae2e24",
        hovered: "#5d1f1a",
      },
    },
    orange: {
      bold: {
        default: "#e56910",
        hovered: "#c25100",
      },
      bolder: {
        default: "#c25100",
        hovered: "#a54800",
      },
      boldest: {
        default: "#a54800",
        hovered: "#702e00",
      },
    },
    yellow: {
      bold: {
        default: "#b38600",
        hovered: "#946f00",
      },
      bolder: {
        default: "#946f00",
        hovered: "#7f5f01",
      },
      boldest: {
        default: "#7f5f01",
        hovered: "#533f04",
      },
    },
    green: {
      bold: {
        default: "#22a06b",
        hovered: "#1f845a",
      },
      bolder: {
        default: "#1f845a",
        hovered: "#216e4e",
      },
      boldest: {
        default: "#216e4e",
        hovered: "#164b35",
      },
    },
    teal: {
      bold: {
        default: "#2898bd",
        hovered: "#227d9b",
      },
      bolder: {
        default: "#227d9b",
        hovered: "#206a83",
      },
      boldest: {
        default: "#206a83",
        hovered: "#164555",
      },
    },
    purple: {
      bold: {
        default: "#8f7ee7",
        hovered: "#8270db",
      },
      bolder: {
        default: "#8270db",
        hovered: "#6e5dc6",
      },
      boldest: {
        default: "#5e4db2",
        hovered: "#352c63",
      },
    },
    magenta: {
      bold: {
        default: "#da62ac",
        hovered: "#cd519d",
      },
      bolder: {
        default: "#cd519d",
        hovered: "#ae4787",
      },
      boldest: {
        default: "#943d73",
        hovered: "#50253f",
      },
    },
    gray: {
      bold: {
        default: "#8590a2",
        hovered: "#758195",
      },
      bolder: {
        default: "#758195",
        hovered: "#626f86",
      },
      boldest: {
        default: "#44546f",
        hovered: "#2c3e5d",
      },
    },
    lime: {
      bold: {
        default: "#6a9a23",
        hovered: "#5b7f24",
      },
      bolder: {
        default: "#5b7f24",
        hovered: "#4c6b1f",
      },
      boldest: {
        default: "#4c6b1f",
        hovered: "#37471f",
      },
    },
  },
};
