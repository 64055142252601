/**
 * @fuegokit/tokens 0.25.1
 * Do not edit directly
 * Generated on Fri, 24 Jan 2025 15:13:57 GMT
 */
export default {
  scale: {
    gray: {
      "100": "#ededed",
      "200": "#e1e1e1",
      "300": "#c4c4c4",
      "400": "#98999a",
      "500": "#797a7c",
      "600": "#565758",
      "700": "#494a4b",
      "800": "#2d2e2e",
      "900": "#191a1a",
      "000": "#f7f7f7",
    },
    neutral: {
      "100": "#f6f7fb",
      "200": "#eceff8",
      "300": "#dcdfec",
      "400": "#c3c6d4",
      "500": "#878ca1",
      "600": "#73788c",
      "700": "#676879",
      "800": "#555a6d",
      "900": "#323338",
      "1000": "#262936",
      "1100": "#1f222d",
      "000": "#ffffff",
    },
    neutralAlpha: {
      "100": "#1f222d08",
      "200": "#1f222d0f",
      "300": "#1f222d24",
      "400": "#1f222d38",
      "500": "#1f222d70",
      "600": "#1f222d85",
      "700": "#1f222d9e",
      "800": "#1f222db8",
      "900": "#1f222dd9",
      "000": "#ffffff00",
    },
    blue: {
      "100": "#aed4fc",
      "200": "#67aef9",
      "300": "#1e89fa",
      "400": "#0073ea",
      "500": "#1f76db",
      "600": "#1f76c2",
      "700": "#0060b9",
      "800": "#003d75",
      "900": "#00284d",
      "000": "#cce5ff",
    },
    teal: {
      "100": "#c1ebf1",
      "200": "#8bdae4",
      "300": "#6acfdc",
      "400": "#38bfd1",
      "500": "#2696a6",
      "600": "#1d717c",
      "700": "#175a63",
      "800": "#12484f",
      "900": "#0e393e",
      "000": "#d6f2f5",
    },
    purple: {
      "100": "#e7d5f6",
      "200": "#d0aeed",
      "300": "#b781e4",
      "400": "#a25ddc",
      "500": "#904acf",
      "600": "#8050ab",
      "700": "#68418b",
      "800": "#4e3168",
      "900": "#342145",
      "000": "#f3eafa",
    },
    green: {
      "100": "#bbdbc9",
      "200": "#b5cec0",
      "300": "#89bda0",
      "400": "#33995f",
      "500": "#00854d",
      "600": "#007a41",
      "700": "#007038",
      "800": "#006130",
      "900": "#004d26",
      "000": "#d6ebdf",
    },
    yellow: {
      "100": "#ffe580",
      "200": "#fbd850",
      "300": "#ffd329",
      "400": "#f5c300",
      "500": "#e5b700",
      "600": "#dbaf00",
      "700": "#c29e11",
      "800": "#755f0a",
      "900": "#463906",
      "000": "#ffefb2",
    },
    red: {
      "100": "#f4c3cb",
      "200": "#ecb7bf",
      "300": "#e06c7d",
      "400": "#dd5569",
      "500": "#d83a52",
      "600": "#c53a4c",
      "700": "#b63546",
      "800": "#76232e",
      "900": "#4b161d",
      "000": "#fbe9ec",
    },
    orange: {
      "100": "#fceba1",
      "200": "#f2d973",
      "300": "#ffcb00",
      "400": "#f5c238",
      "500": "#eaaa15",
      "600": "#c58f12",
      "700": "#aa7909",
      "800": "#7c5803",
      "900": "#5a3f02",
      "000": "#fdf4ce",
    },
    magenta: {
      "100": "#ffc2e0",
      "200": "#ff8ac4",
      "300": "#ff5cad",
      "400": "#ff3399",
      "500": "#ff158a",
      "600": "#e1197e",
      "700": "#c21e71",
      "800": "#610f39",
      "900": "#4b0c2c",
      "000": "#ffe5f2",
    },
    lime: {
      "100": "#e0f1bb",
      "200": "#cde992",
      "300": "#b9e066",
      "400": "#a8d841",
      "500": "#9cd326",
      "600": "#89ba21",
      "700": "#7ca32b",
      "800": "#4d651b",
      "900": "#2e3d10",
      "000": "#ecf6d5",
    },
  },
  elevation: {
    surface: {
      default: {
        "[default]": "#ffffff",
        hovered: "#eceff8",
        pressed: "#dcdfec",
      },
      sunken: "#f6f7fb",
      raised: {
        default: "#ffffff",
        hovered: "#f6f7fb",
        pressed: "#eceff8",
      },
      overlay: {
        default: "#ffffff",
        hovered: "#eceff8",
        pressed: "#dcdfec",
      },
    },
    shadow: {
      overlay: {
        x: 0,
        y: 4,
        blur: 8,
        spread: 0,
        color: "#00000033",
        type: "dropShadow",
      },
      overflow: {
        default: "0px 0px 12px 0px #0304048f, 0px 0px 1px 0px #03040480",
        spread: "#1f222d0f",
        perimeter: "#1f222d24",
      },
      raised: {
        x: 0,
        y: 1,
        blur: 2,
        spread: 0,
        color: "#0000001a",
        type: "dropShadow",
      },
    },
  },
  border: {
    default: "#c3c6d4",
    bold: "#73788c",
    selected: "#0073ea",
    focused: "#1e89fa",
    subtle: "#eceff8",
    input: "#c3c6d4",
    inverse: "#ffffff",
    disabled: "#1f222d0f",
    brand: "#0073ea",
    danger: "#d83a52",
    warning: "#eaaa15",
    success: "#00854d",
    discovery: "#a25ddc",
    information: "#0073ea",
    accent: {
      blue: "#0073ea",
      red: "#d83a52",
      orange: "#eaaa15",
      yellow: "#f5c300",
      green: "#00854d",
      magenta: "#ff158a",
      purple: "#904acf",
      teal: "#2696a6",
      gray: "#878ca1",
    },
    table: {
      default: "#1f222d24",
      container: "#d0d4e4",
    },
  },
  background: {
    accent: {
      blue: {
        subtlest: "#cce5ff",
        subtler: "#aed4fc",
        subtle: "#1e89fa",
        bolder: "#1f76db",
      },
      red: {
        subtlest: "#fbe9ec",
        subtler: "#f4c3cb",
        subtle: "#e06c7d",
        bolder: "#d83a52",
      },
      orange: {
        subtlest: "#fdf4ce",
        subtler: "#fceba1",
        subtle: "#f5c238",
        bolder: "#c58f12",
      },
      yellow: {
        subtlest: "#ffefb2",
        subtler: "#ffe580",
        subtle: "#ffd329",
        bolder: "#dbaf00",
      },
      green: {
        subtlest: "#d6ebdf",
        subtler: "#bbdbc9",
        subtle: "#89bda0",
        bolder: "#007a41",
      },
      purple: {
        subtlest: "#f3eafa",
        subtler: "#e7d5f6",
        subtle: "#b781e4",
        bolder: "#8050ab",
      },
      teal: {
        subtlest: "#d6f2f5",
        subtler: "#c1ebf1",
        subtle: "#6acfdc",
        bolder: "#1d717c",
      },
      magenta: {
        subtlest: "#ffe5f2",
        subtler: "#ffc2e0",
        subtle: "#ff5cad",
        bolder: "#e1197e",
      },
      lime: {
        subtlest: "#ecf6d5",
        subtler: "#e0f1bb",
        subtle: "#a8d841",
        bolder: "#7ca32b",
      },
      gray: {
        subtlest: {
          default: "#eceff8",
          hovered: "#eceff8",
          pressed: "#c3c6d4",
        },
        subtler: {
          default: "#dcdfec",
          hovered: "#c3c6d4",
          pressed: "#878ca1",
        },
        subtle: {
          default: "#878ca1",
          hovered: "#73788c",
          pressed: "#676879",
        },
        bolder: {
          default: "#73788c",
          hovered: "#676879",
          pressed: "#676879",
        },
      },
    },
    input: {
      default: "#ffffff",
      hovered: "#ffffff",
      pressed: "#ffffff",
    },
    inverse: {
      subtle: {
        default: "#1f222d24",
        hovered: "#1f222d38",
        pressed: "#1f222d70",
      },
    },
    neutral: {
      default: {
        "[default]": "#f6f7fb",
        hovered: "#dcdfec",
        pressed: "#dcdfec",
      },
      subtle: {
        default: "#ffffff00",
        hovered: "#dcdfec",
        pressed: "#dcdfec",
      },
      bold: {
        default: "#323338",
        hovered: "#323338",
        pressed: "#323338",
      },
    },
    brand: {
      subtlest: {
        default: "#cce5ff",
        hovered: "#aed4fc",
        pressed: "#67aef9",
      },
      bold: {
        default: "#0073ea",
        hovered: "#0060b9",
        pressed: "#0060b9",
      },
      boldest: {
        default: "#00284d",
        hovered: "#003d75",
        pressed: "#0060b9",
      },
    },
    selected: {
      default: {
        "[default]": "#cce5ff",
        hovered: "#aed4fc",
        pressed: "#67aef9",
      },
      bold: {
        default: "#1f76db",
        hovered: "#0060b9",
        pressed: "#003d75",
      },
    },
    disabled: "#eceff8",
    information: {
      default: {
        "[default]": "#cce5ff",
        hovered: "#aed4fc",
        pressed: "#67aef9",
      },
      bold: {
        default: "#1f76db",
        hovered: "#0060b9",
        pressed: "#003d75",
      },
    },
    danger: {
      default: {
        "[default]": "#f4c3cb",
        hovered: "#ecb7bf",
        pressed: "#e06c7d",
      },
      bold: {
        default: "#d83a52",
        hovered: "#c53a4c",
        pressed: "#76232e",
      },
    },
    success: {
      default: {
        "[default]": "#d6ebdf",
        hovered: "#bbdbc9",
        pressed: "#89bda0",
      },
      bold: {
        default: "#00854d",
        hovered: "#007038",
        pressed: "#006130",
      },
    },
    discovery: {
      default: {
        "[default]": "#f3eafa",
        hovered: "#e7d5f6",
        pressed: "#d0aeed",
      },
      bold: {
        default: "#8050ab",
        hovered: "#68418b",
        pressed: "#4e3168",
      },
    },
    warning: {
      default: {
        "[default]": "#fdf4ce",
        hovered: "#fceba1",
        pressed: "#f2d973",
      },
      bold: {
        default: "#ffcb00",
        hovered: "#eaaa15",
        pressed: "#c58f12",
      },
    },
  },
  blanket: {
    default: "#292f4cb3",
    selected: "#0060b914",
    danger: "#b635461a",
  },
  text: {
    default: "#323338",
    subtle: "#676879",
    subtlest: "#878ca1",
    disabled: "#1f222d85",
    selected: "#0073ea",
    inverse: "#ffffff",
    success: "#00854d",
    danger: "#d83a52",
    information: "#0060b9",
    warning: {
      default: "#7c5803",
      inverse: "#262936",
    },
    discovery: "#68418b",
    brand: "#0073ea",
    accent: {
      blue: {
        default: "#1f76c2",
        bolder: "#003d75",
      },
      red: {
        default: "#b63546",
        bolder: "#76232e",
      },
      orange: {
        default: "#7c5803",
        bolder: "#5a3f02",
      },
      yellow: {
        default: "#755f0a",
        bolder: "#463906",
      },
      green: {
        default: "#007a41",
        bolder: "#006130",
      },
      purple: {
        default: "#8050ab",
        bolder: "#4e3168",
      },
      teal: {
        default: "#1d717c",
        bolder: "#12484f",
      },
      magenta: {
        default: "#c21e71",
        bolder: "#610f39",
      },
      gray: {
        default: "#494a4b",
        bolder: "#191a1a",
      },
      lime: {
        default: "#4d651b",
        bolder: "#2e3d10",
      },
    },
  },
  link: {
    default: "#1f76c2",
    hovered: "#1f76c2",
    pressed: "#1f76c2",
    visited: "#1f76c2",
  },
  icon: {
    accent: {
      blue: "#0073ea",
      red: "#d83a52",
      orange: "#eaaa15",
      yellow: "#e5b700",
      green: "#00854d",
      purple: "#904acf",
      teal: "#2696a6",
      magenta: "#ff158a",
      gray: "#73788c",
      lime: "#89ba21",
    },
    default: "#323338",
    subtle: "#676879",
    inverse: "#ffffff",
    disabled: "#c3c6d4",
    brand: "#0073ea",
    selected: "#0073ea",
    danger: "#d83a52",
    success: "#00854d",
    discovery: "#904acf",
    information: "#1f76c2",
    warning: {
      default: "#c58f12",
      inverse: "#262936",
    },
  },
  interaction: {
    hovered: "#1f222d24",
    pressed: "#1f222d38",
  },
  skeleton: {
    default: "#1f222d0f",
    subtle: "#1f222d08",
  },
  opacity: {
    disabled: 0.38,
    loading: 0.7,
  },
  chart: {
    brand: {
      default: "#0073ea",
      hovered: "#1f76db",
    },
    neutral: {
      default: "#878ca1",
      hovered: "#73788c",
    },
    success: {
      default: {
        "[default]": "#00854d",
        hovered: "#007a41",
      },
      bold: {
        default: "#007038",
        hovered: "#006130",
      },
    },
    danger: {
      default: {
        "[default]": "#dd5569",
        hovered: "#d83a52",
      },
      bold: {
        default: "#b63546",
        hovered: "#76232e",
      },
    },
    warning: {
      default: {
        "[default]": "#eaaa15",
        hovered: "#c58f12",
      },
      bold: {
        default: "#aa7909",
        hovered: "#7c5803",
      },
    },
    information: {
      default: {
        "[default]": "#0073ea",
        hovered: "#1f76db",
      },
      bold: {
        default: "#0060b9",
        hovered: "#003d75",
      },
    },
    discovery: {
      default: {
        "[default]": "#a25ddc",
        hovered: "#904acf",
      },
      bold: {
        default: "#68418b",
        hovered: "#4e3168",
      },
    },
    categorical: {
      "1": {
        default: "#2696a6",
        hovered: "#1d717c",
      },
      "2": {
        default: "#68418b",
        hovered: "#4e3168",
      },
      "3": {
        default: "#eaaa15",
        hovered: "#c58f12",
      },
      "4": {
        default: "#c21e71",
        hovered: "#610f39",
      },
      "5": {
        default: "#003d75",
        hovered: "#00284d",
      },
      "6": {
        default: "#a25ddc",
        hovered: "#904acf",
      },
      "7": {
        default: "#610f39",
        hovered: "#4b0c2c",
      },
      "8": {
        default: "#aa7909",
        hovered: "#7c5803",
      },
    },
    blue: {
      bold: {
        default: "#1e89fa",
        hovered: "#0073ea",
      },
      bolder: {
        default: "#1f76db",
        hovered: "#1f76c2",
      },
      boldest: {
        default: "#0060b9",
        hovered: "#003d75",
      },
    },
    red: {
      bold: {
        default: "#dd5569",
        hovered: "#d83a52",
      },
      bolder: {
        default: "#c53a4c",
        hovered: "#b63546",
      },
      boldest: {
        default: "#76232e",
        hovered: "#4b161d",
      },
    },
    orange: {
      bold: {
        default: "#eaaa15",
        hovered: "#c58f12",
      },
      bolder: {
        default: "#c58f12",
        hovered: "#aa7909",
      },
      boldest: {
        default: "#aa7909",
        hovered: "#7c5803",
      },
    },
    yellow: {
      bold: {
        default: "#e5b700",
        hovered: "#dbaf00",
      },
      bolder: {
        default: "#dbaf00",
        hovered: "#c29e11",
      },
      boldest: {
        default: "#c29e11",
        hovered: "#755f0a",
      },
    },
    green: {
      bold: {
        default: "#00854d",
        hovered: "#007a41",
      },
      bolder: {
        default: "#007a41",
        hovered: "#007038",
      },
      boldest: {
        default: "#007038",
        hovered: "#006130",
      },
    },
    teal: {
      bold: {
        default: "#2696a6",
        hovered: "#1d717c",
      },
      bolder: {
        default: "#1d717c",
        hovered: "#175a63",
      },
      boldest: {
        default: "#175a63",
        hovered: "#12484f",
      },
    },
    purple: {
      bold: {
        default: "#a25ddc",
        hovered: "#904acf",
      },
      bolder: {
        default: "#904acf",
        hovered: "#8050ab",
      },
      boldest: {
        default: "#68418b",
        hovered: "#4e3168",
      },
    },
    magenta: {
      bold: {
        default: "#ff3399",
        hovered: "#ff158a",
      },
      bolder: {
        default: "#ff158a",
        hovered: "#e1197e",
      },
      boldest: {
        default: "#c21e71",
        hovered: "#610f39",
      },
    },
    gray: {
      bold: {
        default: "#878ca1",
        hovered: "#73788c",
      },
      bolder: {
        default: "#73788c",
        hovered: "#676879",
      },
      boldest: {
        default: "#555a6d",
        hovered: "#323338",
      },
    },
    lime: {
      bold: {
        default: "#9cd326",
        hovered: "#89ba21",
      },
      bolder: {
        default: "#89ba21",
        hovered: "#7ca32b",
      },
      boldest: {
        default: "#7ca32b",
        hovered: "#4d651b",
      },
    },
  },
};
