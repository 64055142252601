/**
 * @fuegokit/tokens 0.25.1
 * Do not edit directly
 * Generated on Fri, 24 Jan 2025 15:14:05 GMT
 */
export default {
  radii: {
    "0": "0px",
    "1": "4px",
    "2": "8px",
    "3": "16px",
    "4": "20px",
    "5": "99999px",
    none: "0px",
    xsmall: "2px",
    small: "4px",
    medium: "6px",
    large: "8px",
    xlarge: "16px",
    round: "99999px",
  },
};
