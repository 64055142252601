/**
 * @fuegokit/tokens 0.25.1
 * Do not edit directly
 * Generated on Fri, 24 Jan 2025 15:13:52 GMT
 */
export default {
  scale: {
    orange: {
      "100": "#ffd6c4",
      "200": "#ffad89",
      "300": "#ff996c",
      "400": "#ed7c4a",
      "500": "#be633b",
      "600": "#8e4a2c",
      "700": "#5f321e",
      "800": "#2f190f",
      "900": "#180c07",
      "000": "#fff5f0",
    },
    blue: {
      "100": "#b0caff",
      "200": "#6195ff",
      "300": "#397bff",
      "400": "#336fe6",
      "500": "#2256c5",
      "600": "#224a99",
      "700": "#173166",
      "800": "#0c1933",
      "900": "#070f1f",
      "000": "#ebf2ff",
    },
    teal: {
      "100": "#d1fef9",
      "200": "#a4fdf4",
      "300": "#8dfcf1",
      "400": "#7fe3d9",
      "500": "#71cac1",
      "600": "#559791",
      "700": "#386560",
      "800": "#1c3330",
      "900": "#111e1d",
      "000": "#f4fffe",
    },
    purple: {
      "100": "#ddcbff",
      "200": "#ba97ff",
      "300": "#a97dff",
      "400": "#9871e6",
      "500": "#8764cc",
      "600": "#654b99",
      "700": "#443266",
      "800": "#221933",
      "900": "#140f1f",
      "000": "#f6f2ff",
    },
    white: "#ffffff",
    black: "#1e1e1e",
    yellow: {
      "100": "#fffbe2",
      "200": "#fff8c5",
      "300": "#fff6b6",
      "400": "#ffee70",
      "500": "#f0da4e",
      "600": "#c0ae3e",
      "700": "#90832f",
      "800": "#484117",
      "900": "#181608",
      "000": "#fffef8",
    },
    red: {
      "100": "#fbb4b4",
      "200": "#f66969",
      "300": "#f44343",
      "400": "#dc3c3c",
      "500": "#c33636",
      "600": "#922828",
      "700": "#621b1b",
      "800": "#310e0e",
      "900": "#1d0808",
      "000": "#feecec",
    },
    green: {
      "100": "#b7dfb9",
      "200": "#b7dfb9",
      "300": "#4caf50",
      "400": "#449e48",
      "500": "#358238",
      "600": "#2e6930",
      "700": "#1e4620",
      "800": "#0f2310",
      "900": "#09150a",
      "000": "#edf7ee",
    },
    neutral: {
      "100": "#121213",
      "200": "#1c1b1c",
      "300": "#2e2d2f",
      "400": "#5c5b5e",
      "500": "#8a888e",
      "600": "#a19fa5",
      "700": "#b8b6bd",
      "800": "#cfccd4",
      "900": "#e6e3ec",
      "1000": "#f3f1f6",
      "1100": "#faf9fb",
      "000": "#090a0f",
    },
    neutralAlpha: {
      "100": "#b8b6bd0f",
      "200": "#b8b6bd1a",
      "300": "#b8b6bd33",
      "350": "#b8b6bd54",
      "400": "#b8b6bd78",
      "500": "#b8b6bdba",
      "000": "#00000000",
    },
    gradient: {
      orangeYellow: "linear-gradient(90deg, #FF996C 0%, #FFF6B6 100%)",
      yellowPurple: "linear-gradient(90deg, #FFF6B6 0%, #A97DFF 100%)",
      bluePurple: "linear-gradient(90deg, #397BFF 0%, #A97DFF 100%)",
      yellowBlue: "linear-gradient(90deg, #FFF6B6 0%, #397BFF 100%)",
      orangeTeal: "linear-gradient(90deg, #FF996C 0%, #8DFCF1 99%)",
      orangePurple: "linear-gradient(90deg, #FF996C 0%, #A97DFF 100%)",
      blueOrange: "linear-gradient(90deg, #397BFF 0%, #FF996C 99%)",
      purpleTeal: "linear-gradient(90deg, #A97DFF 0%, #8DFCF1 99%)",
      tealBlue: "linear-gradient(90deg, #8DFCF1 0%, #397BFF 100%)",
      tealYellow: "linear-gradient(90deg, #8DFCF1 0%, #FFF6B6 100%)",
    },
  },
  text: {
    brand: "#397bff",
    inverse: "#090a0f",
    default: "#ffffff",
    subtle: {
      default: "#cfccd4",
      inverse: "#5c5b5e",
    },
    subtlest: {
      default: "#a19fa5",
      inverse: "#a19fa5",
    },
    danger: {
      default: "#f44343",
      inverse: "#c33636",
    },
    success: {
      default: "#4caf50",
      inverse: "#358238",
    },
    disabled: "#5c5b5e",
    selected: "#173166",
    accent: {
      blue: {
        default: "#397bff",
        bolder: "#b0caff",
      },
      red: {
        default: "#f44343",
        bolder: "#feecec",
      },
      orange: {
        default: "#ff996c",
        bolder: "#ffd6c4",
      },
      yellow: {
        default: "#ffee70",
        bolder: "#fffbe2",
      },
      green: {
        default: "#4caf50",
        bolder: "#b7dfb9",
      },
      purple: {
        default: "#9871e6",
        bolder: "#ddcbff",
      },
      teal: {
        default: "#8dfcf1",
        bolder: "#d1fef9",
      },
      gray: {
        default: "#8a888e",
        bolder: "#cfccd4",
      },
    },
  },
  link: {
    default: "#397bff",
    hovered: "#397bff",
    pressed: "#6195ff",
    visited: "#ba97ff",
  },
  icon: {
    brand: "#397bff",
    inverse: "#121213",
    danger: {
      default: "#f44343",
      inverse: "#c33636",
    },
    success: {
      default: "#4caf50",
      inverse: "#358238",
    },
    warning: {
      default: "#fff8c5",
      inverse: "#090a0f",
    },
    disabled: "#8a888e",
    accent: {
      blue: {
        default: "#397bff",
        bolder: "#b0caff",
      },
      red: {
        default: "#f44343",
        bolder: "#feecec",
      },
      orange: {
        default: "#ff996c",
        bolder: "#ffd6c4",
      },
      yellow: {
        default: "#ffee70",
        bolder: "#fffbe2",
      },
      green: {
        default: "#4caf50",
        bolder: "#b7dfb9",
      },
      purple: {
        default: "#9871e6",
        bolder: "#ddcbff",
      },
      teal: {
        default: "#7fe3d9",
        bolder: "#d1fef9",
      },
      gray: {
        default: "#5c5b5e",
        bolder: "#cfccd4",
      },
    },
    default: "#ffffff",
  },
  border: {
    brand: "#397bff",
    bold: "#a19fa5",
    inverse: "#090a0f",
    focused: {
      default: "#6195ff",
      inverse: "#173166",
    },
    danger: {
      default: "#f44343",
      inverse: "#c33636",
    },
    success: {
      default: "#4caf50",
      inverse: "#358238",
    },
    input: {
      default: "#ffffff",
      hovered: "#397bff",
    },
    disabled: "#b8b6bd78",
    default: "#ffffff",
    accent: {
      blue: "#397bff",
      red: "#f44343",
      orange: "#ff996c",
      yellow: "#ffee70",
      green: "#4caf50",
      purple: "#a97dff",
      teal: "#7fe3d9",
      gray: "#8a888e",
    },
    subtle: {
      default: "#5c5b5e",
      inverse: "#cfccd4",
    },
    table: {
      default: "#b8b6bd33",
      container: "#00000000",
    },
  },
  background: {
    brand: {
      bold: {
        default: "#336fe6",
        hovered: "#2256c5",
        pressed: "#224a99",
      },
      default: {
        "[default]": "#336fe6",
        hovered: "#397bff",
        pressed: "#6195ff",
      },
    },
    disabled: "#b8b6bd1a",
    success: {
      default: "#0f2310",
      inverse: "#edf7ee",
    },
    danger: {
      default: {
        "[default]": "#310e0e",
        hovered: "#621b1b",
        pressed: "#922828",
      },
      bold: {
        default: "#f44343",
        hovered: "#f66969",
        pressed: "#fbb4b4",
      },
      inverse: "#feecec",
    },
    warning: {
      default: {
        "[default]": "#484117",
        hovered: "#90832f",
        pressed: "#c0ae3e",
      },
      bold: {
        default: "#fff6b6",
        hovered: "#fff8c5",
        pressed: "#fffbe2",
      },
      inverse: "#fffef8",
    },
    accent: {
      blue: {
        subtlest: "#0c1933",
        subtler: "#224a99",
        subtle: "#2256c5",
        bolder: "#397bff",
      },
      red: {
        subtlest: "#310e0e",
        subtler: "#621b1b",
        subtle: "#922828",
        bolder: "#f44343",
      },
      orange: {
        subtlest: "#2f190f",
        subtler: "#8e4a2c",
        subtle: "#be633b",
        bolder: "#ff996c",
      },
      yellow: {
        subtlest: "#484117",
        subtler: "#c0ae3e",
        subtle: "#f0da4e",
        bolder: "#fff6b6",
      },
      green: {
        subtlest: "#0f2310",
        subtler: "#2e6930",
        subtle: "#358238",
        bolder: "#4caf50",
      },
      teal: {
        subtlest: "#1c3330",
        subtler: "#559791",
        subtle: "#71cac1",
        bolder: "#8dfcf1",
      },
      purple: {
        subtlest: "#221933",
        subtler: "#654b99",
        subtle: "#8764cc",
        bolder: "#a97dff",
      },
      gray: {
        subtlest: {
          default: "#1c1b1c",
          hovered: "#121213",
          pressed: "#090a0f",
        },
        subtler: {
          default: "#2e2d2f",
          hovered: "#1c1b1c",
          pressed: "#121213",
        },
        subtle: {
          default: "#8a888e",
          hovered: "#5c5b5e",
          pressed: "#2e2d2f",
        },
        bolder: {
          default: "#b8b6bd",
          hovered: "#a19fa5",
          pressed: "#8a888e",
        },
      },
      gradient: {
        orangeYellow: "linear-gradient(90deg, #FF996C 0%, #FFF6B6 100%)",
        yellowPurple: "linear-gradient(90deg, #FFF6B6 0%, #A97DFF 100%)",
        bluePurple: "linear-gradient(90deg, #397BFF 0%, #A97DFF 100%)",
        yellowBlue: "linear-gradient(90deg, #FFF6B6 0%, #397BFF 100%)",
        orangeTeal: "linear-gradient(90deg, #FF996C 0%, #8DFCF1 99%)",
        orangePurple: "linear-gradient(90deg, #FF996C 0%, #A97DFF 100%)",
        blueOrange: "linear-gradient(90deg, #397BFF 0%, #FF996C 99%)",
        purpleTeal: "linear-gradient(90deg, #A97DFF 0%, #8DFCF1 99%)",
        tealBlue: "linear-gradient(90deg, #8DFCF1 0%, #397BFF 100%)",
        tealYellow: "linear-gradient(90deg, #8DFCF1 0%, #FFF6B6 100%)",
      },
    },
    neutral: {
      subtle: {
        default: "#00000000",
        hovered: "#b8b6bd1a",
        pressed: "#b8b6bd33",
      },
    },
    selected: {
      default: {
        "[default]": "#070f1f",
        hovered: "#0c1933",
        pressed: "#0c1933",
      },
      bold: {
        default: "#397bff",
        hovered: "#6195ff",
        pressed: "#b0caff",
      },
    },
  },
  surface: {
    default: "#090a0f",
    inverse: "#ffffff",
    raised: {
      default: "#121213",
      hovered: "#1c1b1c",
      pressed: "#2e2d2f",
    },
  },
  elevation: {
    surface: {
      inverse: "#ffffff",
      default: {
        "[default]": "#090a0f",
        hovered: "#1c1b1c",
        pressed: "#2e2d2f",
      },
      raised: {
        default: "#121213",
        hovered: "#1c1b1c",
        pressed: "#2e2d2f",
      },
    },
  },
  opacity: {
    disabled: 0.38,
    loading: 0.7,
  },
  skeleton: {
    default: "#b8b6bd54",
    subtle: "#b8b6bd0f",
  },
};
